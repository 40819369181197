<template>
	<div class="chat-window">
		<div class="chat-title">发邮件</div>
		<div class="chat-container">
			<div class="top-button">
				<el-button round size="small" @click="handleSend">发送</el-button>
				<el-button v-if="!id" round size="small" @click="saveDraft">存草稿</el-button>
			</div>
			<div class="inbox-content">
				<el-form :model="model" size="small" label-width="60px" class="content">
					<el-form-item label="发件人">
						<el-input v-model="sendName" disabled class="input-style"></el-input>
					</el-form-item>
					<el-form-item label="收件人">
						<el-select v-model="model.receiveMemberId" style="width:100%;" placeholder="请选择收件人" @change="changeSelect"
							@visible-change="handleRecipients">
							<el-option v-for="item in addressInfoList" :key="item.memberId" :label="item.emilName"
								:value="item.memberId + ''"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="主题">
						<label slot="label">主&nbsp;&nbsp;&nbsp;&nbsp;题</label>
						<el-input v-model="model.subject" class="input-style"></el-input>
					</el-form-item>
					<el-form-item>
						<div class="item">
							<img src="@/assets/images/ic_fujian1@3x.png">
							<file-upload title="文档附件" @emitEmailInput="fileInput"></file-upload>
						</div>
						<div class="item">
							<img src="@/assets/images/ic_fujian2@3x.png">
							<single-upload :accept="accept" :showList="false" @emitEmailInput="fileInput"
								title="图片附件"></single-upload>
						</div>
						<div class="item">
							<img src="@/assets/images/ic_fujian3@3x.png">
							<large-file-uplod title="超大附件" @emitEmailInput="fileInput"></large-file-uplod>
						</div>
					</el-form-item>
					<el-form-item label="" v-if="model.memberMailAppendices&&model.memberMailAppendices.length>0">
						<div class="file-item" v-for="(item,index) in model.memberMailAppendices" :key="index">
							<div class="file-item-name">
								{{item.appendixName}}
							</div>
							<div class="file-item-close" @click="delFile(index)">
								<!-- × -->
								撤销
							</div>
						</div>
					</el-form-item>
					<el-form-item label="正文">
						<el-input v-model="model.sourceContent" type="textarea" :rows="10" resize="none"
							class="input-style"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="submit">
				<div class="submit-button">
					<el-button round size="small" @click="handleSend">发送</el-button>
					<el-button v-if="!id" round size="small" @click="saveDraft">存草稿</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/api/api'
	import SingleUpload from '@/components/Upload/singleUpload'
	import fileUpload from '@/components/Upload/fileUpload'
	import largeFileUplod from '@/components/Upload/largeFileUplod'
	import eventBus from './js/eventBus';

	export default {
		// 发邮件
		name: 'sendEmails',
		components: {
			SingleUpload,
			fileUpload,
			largeFileUplod
		},
		data() {
			return {
				model: {
					receiveMemberId: '', //接收人id
					sendMemberId: '', //发送人id
					subject: '', //主题
					memberMailAppendices: [], //附件数组
					sourceContent: '', //内容
					receiveMemberMail: '', //收件人邮箱
					sendMemberMail: '', //发件人邮箱
				},
				accept: '.jpeg,.png,.jpg,.bmp,.gif',
				fileNameList: [],
				sendName: '', //发件人名称
				placeholderText: '请选择收件人',
				loginMsg: null,
				optionList: [],
				addressInfoList: [],
				id: null // 邮件ID此处当前回复功能使用
			}
		},
		created() {
			if (localStorage.getItem('loginMsg')) {
				this.loginMsg = JSON.parse(localStorage.getItem('loginMsg'))
				this.sendName = this.loginMsg.nickName + '<' + this.loginMsg.userName + '@mm.com>'
				this.model.sendMemberMail = this.sendName
				this.model.sendMemberId = this.loginMsg.memberId
			}
			this.getAddressBookList()
			if (this.$route.query.id) {
				this.id = this.$route.query.id
				this.getEmailDetail()
			}
			if (this.$route.query.model) {
				this.model = JSON.parse(this.$route.query.model)
			}
			if (this.$route.query.val) {
				console.log("==========", JSON.parse(this.$route.query.val))
				const user = JSON.parse(this.$route.query.val)
				this.model.receiveMemberId = user.memberId
				this.model.receiveMemberMail = user.nickName + '<' + user.userName + '@mm.com>'
			}
		},
		methods: {
			//获取邮件详情
			getEmailDetail() {
				this.$api.email.emailDetail({
					id: this.id,
				}).then((res) => {
					console.log("邮件详情", res.result)
					const detail = res.result
					this.model.receiveMemberId = detail.sendMemberId
					this.model.receiveMemberMail = detail.sendMemberMail
					this.model.subject = '回复：' + detail.subject || ''
					this.model.sourceContent =
						`------------------ 原始邮件 ------------------\n${detail.subject || ''}\n发件人：${detail.sendMemberMail}\n收件人：${detail.receiveMemberMail}\n时间：${detail.createTime}\n\n${detail.sourceContent}\n`
					this.model.memberMailAppendices = detail.memberMailAppendices || []
				})
			},
			changeSelect(val) {
				console.log(val)
			},
			//附件文件上传成功
			fileInput(val) {
				console.log(val)
				this.model.memberMailAppendices.push(val)
			},
			//删除附件
			delFile(i) {
				this.model.memberMailAppendices.splice(i, 1)
			},
			//获取通讯录列表
			getAddressBookList() {
				//自己构造数组 构造有首字母缩写的的二级数组
				api.addressList.memberList().then(res => {
					this.addressInfoList = res.result
					if (this.addressInfoList.length > 0) {
						this.addressInfoList.forEach(item => {
							this.$set(item, 'emilName', item.nickName + '<' + item.userName + '@mm.com>')
						})
					}
				}).catch(err => {
					this.$message({
						showClose: true,
						message: err.message,
						type: 'error'
					});
				})
			},
			// 发送
			handleSend() {
				if (this.model.sendMemberId == '') {
					this.$message({
						message: '请先选择发件人',
						type: 'warning'
					});
					return
				}
				if (this.model.receiveMemberId == '') {
					this.$message({
						message: '请先选择收件人',
						type: 'warning'
					});
					return
				}
				if (this.model.subject == '') {
					this.$message({
						message: '请先输入主题',
						type: 'warning'
					});
					return
				}
				if (this.model.sourceContent == '') {
					this.$message({
						message: '请先输入正文',
						type: 'warning'
					});
					return
				}
				this.model.sourceType = 0
				this.model.mailType = 0
				this.$api.email.sendEmail(this.model).then(res => {
					this.clearForm()
					this.$message({
						message: '发送成功',
						type: 'success'
					});
					this.$emit('sendOk')
				})
			},
			// 存草稿
			saveDraft() {
				if (this.model.sendMemberId == '') {
					this.$message({
						message: '请先选择发件人',
						type: 'warning'
					});
					return
				}
				if (this.model.receiveMemberId == '') {
					this.$message({
						message: '请先选择收件人',
						type: 'warning'
					});
					return
				}
				if (this.model.subject == '') {
					this.$message({
						message: '请先输入主题',
						type: 'warning'
					});
					return
				}
				if (this.model.sourceContent == '') {
					this.$message({
						message: '请先输入正文',
						type: 'warning'
					});
					return
				}
				this.model.sourceType = 1
				this.model.mailType = 1
				this.$api.email.saveEmail(this.model).then(res => {
					this.clearForm()
					this.$message({
						message: '存草稿成功',
						type: 'success'
					});
					this.$emit('saveEmailOk')
					this.$emit('sendOk')
				})
			},
			//清空表单
			clearForm() {
				for (let key in this.model) {
					if (key != 'memberMailAppendices') {
						this.model[key] = ''
					} else {
						this.model[key] = []
					}
				}
				this.fileNameList = []
			},
			// 选择收件人
			handleRecipients() {
				this.$router.push({
					path: 'recipientsDetail',
					query: {
						model: JSON.stringify(this.model)
					}
				})
			},
		}
	}
</script>

<style scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border-left: 1px solid #dcdfe6;
		position: relative;
	}

	.chat-window .chat-title {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #ffffff;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-window .chat-container {
		min-height: 540px;
		height: 100%;
		width: 100%;
		/* padding-top: 20px; */
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		/* overflow-x: hidden;
        overflow-y: auto; */
		/* position: relative; */
	}

	.top-button {
		height: 47px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 10px;
		border-bottom: 1px solid #dcdfe6;
	}

	.top-button button {
		width: 56px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.top-button button:nth-child(1) {
		width: 48px;
	}

	.inbox-content {
		flex: 1;
		padding: 20px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.content .el-form-item:nth-child(4)>>>.el-form-item__content {
		display: flex;
		flex-direction: row;
	}

	.inbox-content .content .item {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 20px;
		cursor: pointer;
	}

	.content .item img {
		width: 16px;
		height: 16px;
		margin-right: 4px;
	}

	.content .item p {
		font-size: 12px;
		color: #7b7d80;
	}

	.content .item>>>.el-upload {
		font-size: 12px;
		color: #7b7d80;
	}

	.submit {
		width: 100%;
		height: 68px;
		padding-left: 10px;
		padding-right: 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		/* position: absolute;
        bottom: 0; */
		border-top: 1px solid #dcdfe6;
	}

	.submit-button {
		display: flex;
		flex-direction: row;
	}

	.submit button {
		width: 56px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.submit button:nth-child(1) {
		width: 48px;
	}

	.file-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.file-item-close {
		font-size: 13px;
		color: #FF6632;
		cursor: pointer;
	}
</style>